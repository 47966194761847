<template>
    <BT-Blade-Item
        bladeName="receival-archive"
        :bladesData="bladesData"
        customURL="/get/byConsignmentID"
        navigation="receival-archives"
        title="Receival">
        <template v-slot="{ item }">

            <BT-Field-Select
                v-model="item.courierID"
                navigation="couriers" />

            <BT-Field-Select
                v-model="item.destinationLocationID"
                navigation="locations"
                itemText="locationName" />
            
            <BT-Field-Date
                label="Delivered On"
                v-model="item.deliveredOn"
                shortDateAndTime />
            
            <BT-Signature-Overlay v-model="item.proofOfDelivery" />

            <BT-Field-Select
                v-model="item.receivedByUserID"
                navigation="users"
                itemText="userName" />

            <v-expansion-panels v-model="panelV">
                <BT-Expansion-Panel-List
                    :items="item.packagedItems"
                    label="Packages">
                    <template v-slot="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>
                                <BT-Entity 
                                    navigation="measurements"
                                    :itemValue="item.measurementID"
                                    itemText="measurementName" />
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            {{ item.quantityDelivered | toDisplayNumber }}
                        </v-list-item-action>
                    </template>
                </BT-Expansion-Panel-List>
            </v-expansion-panels>

            
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Receival-Consignment-Archive-Blade',
    data: function() {
        return {
            panelV: 0
        }
    },
    props: {
        bladesData: null
    }
}
</script>